import React from "react"
import Box from "@mui/material/Box"
import { useTheme } from "@mui/material/styles"
import { Button, Link, Typography, useMediaQuery } from "@mui/material"

export const UserPageFooter = ({footerType}) => {
  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  if (footerType === "login") {
    return (
      <Box width={0.8} my={sm ? 3 : 4}>
        <Typography
          variant="body2"
          fontWeight={400}
          align="center"
          fontSize={sm ? 15 : 20}
          color="text.secondary"
          textTransform="none"
        >
          Already a user? <Link href="/login">Login now</Link>
        </Typography>
      </Box>
    )
  }

  if (footerType === "signup") {
    return (
      <Box width={0.8} my={sm ? 3 : 4}>
        <Typography
          variant="body2"
          fontWeight={400}
          align="center"
          fontSize={sm ? 15 : 20}
          color="text.secondary"
          textTransform="none"
        >
          Don’t have account? <Link href="/signup">Signup now</Link>
        </Typography>
      </Box>
    )
  }
}
