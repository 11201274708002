import React from "react"
import { Badge, Typography } from "@mui/material"
import capitalize from "@mui/utils/capitalize"
import Box from "@mui/material/Box"

export const SentimentAnalysisBadge = ({ sentimentString = "", size }) => {
  let color = "default"

  if (sentimentString.includes("positive")) {
    color = "success"
  } else if (sentimentString.includes("negative")) {
    color = "error"
  }

  return (
    <Box color={color} sx={theme => ({
      "&": {
        background: (color === "default" ? theme.palette.text.disabled : theme.palette[color].main),
        padding: 0.2,
        pt: size === "small" ? 0.2 : 0.4,
        px: size === "small" ? 0.5 : 1,
        color: "white",
        width: size === "small" ? "8px" : "fit-content",
        visibility: !sentimentString && "hidden",
        borderRadius: 6,
        textAlign:  size === "small" ? "center" : undefined
      }
    })}>
      {size === "small" ? (
        <Typography variant="body2" fontSize={9}>
          {capitalize(sentimentString.charAt(0))}
        </Typography>
      ) : (
        <Typography variant="body2" fontSize={12}>
          {capitalize(sentimentString)}
        </Typography>
      )}
      {/*{capitalize(sentimentString.charAt(0))}*/}
    </Box>
  )
}
